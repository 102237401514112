<template>
    <v-app>
        <div class="login">
            <h2 class="login__title" ref="logo">SEOWORK ADMIN</h2>

            <div class="login__wrapper">
                <h1 class="login__subtitle">{{ title }}</h1>
                <v-form
                    class="pb-2"
                    @submit.prevent="submit()"
                    ref="form"
                    lazy-validation
                >
                    <v-container>
                        <v-col
                            cols="12"
                        >
                            <v-text-field
                                v-model="form.login"
                                placeholder="Ваш логин"
                                label="Логин"
                                outlined
                                :disabled="preloader"
                                :rules="[rules.requiredLogin]"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                v-model="form.password"
                                :error="errors.password"
                                placeholder="Ваш пароль"
                                label="Пароль"
                                outlined
                                :disabled="preloader"
                                :type="showPassword ? 'text' : 'password'"
                                :rules="[rules.requiredPassword]"
                                @click:append="showPassword = !showPassword"
                            />
                        </v-col>

                        <v-col>
                            <v-progress-linear
                                v-if="preloader"
                                indeterminate
                                color="blue darken-2"
                            ></v-progress-linear>
                        </v-col>

                        <v-layout justify-center>
                            <v-btn
                                color="primary"
                                type="submit"
                            >
                                Войти
                            </v-btn>
                        </v-layout>

                    </v-container>

                </v-form>
            </div>

            <div class="login__copy">© 2021, Seowork</div>
        </div>
    </v-app>
</template>

<script>
import { mapState } from 'vuex'
import { logIn } from '@/function/auth.js'

export default {
	metaInfo () {
		return {
			title: this.title
		}
	},

	data () {
		return {
			title: 'Вход',

			preloader: false,

			form: {
				login: '',
				password: ''
				// ttl: 15
			},

			showPassword: false,

			errors: {},

			valid: false,

			rules: {
				requiredLogin: value => !!value || 'Введите логин',
				requiredPassword: value => !!value || 'Введите пароль'
			}
		}
	},

	computed: {
		...mapState({
			config: state => state.config,
			request: state => state.request
		})
	},

	methods: {
		/* Установка фокуса при ошибке */
		viewErrors (ref, error = 'Ошибка авторизации') {
			this.$notify({
				type: 'error',
				title: error
			})

			if (!ref) {
				setTimeout(() => {
					this.$set(this, 'preloader', false)
				}, 750)
			}

			/* Возвращение ошибки */
			return error
		},

		/* Отправка запроса */
		submit () {
			try {
				if (this.preloader || !this.$refs.form.validate()) return

				this.$set(this, 'preloader', true)

				this.request({
					type: 'POST',
					url: '/auth',
					body: this.form
				}).then(async response => {
					const tokens = response.data
					if (tokens.access_token && tokens.refresh_token && logIn(this.config, tokens)) {
						if (this.$route.query.to) {
							this.$router.push({ path: this.$route.query.to })
						} else {
							this.$router.push({ name: 'dashboard' })
						}
					} else {
						this.viewErrors()
					}
				}, () => {
					this.viewErrors()
				})
			} catch (_) {
				this.viewErrors()
			}
		}
	}
}
</script>

<style lang="scss" scoped src="../styles/auth.scss"/>
